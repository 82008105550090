module.exports = {
  locales: ['en-ca', 'en-us', 'fr-ca'],
  defaultLocale: 'en-ca',
  localeDetection: false,
  pages: {
    '*': ['application', 'home'],
    '/': ['home'],
    '/home': ['home'],
  },
};
