
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useEffect, ReactElement, ReactNode } from 'react';
import Head from 'next/head';
import '../styles/globals.scss';
import type { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  // expose global variable to be able to detect
  // that scripts loading is blocked by adblocker
  useEffect(() => {
    window.flipp = true;
  }, []);

  return getLayout(
    <>
      <Head>
        <title>Flipp</title>
      </Head>
      <Component {...pageProps} />
    </>,
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: '/' });
    appContext.ctx.res.end();
  }

  return { ...appProps };
};

const __Page_Next_Translate__ = withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
})(MyApp);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  